import styled from "styled-components";
import { Sidebar, Header } from "../components/index";
import { useEffect, useState } from "react";
import { BlueButton, Content, Search, TitleText } from "../styles";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useRef } from "react";
import AddExpertModal from "../modals/AddExpertModal";
import EditExpertModal from "../modals/EditExpertModal";

const CopyExperts = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const [defaultExperts, setDefaultExperts] = useState([]);
  const [searchExperts, setSearchExperts] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isUnSubscribing, setIsUnSubscribing] = useState(false);
  //   const [search, setSearch] = useState('')
  const searchRef = useRef();
  const [addExpert, setAddExpert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editExpert, setEditExpert] = useState(false);

  const [expert, setExpert] = useState({});

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  // useEffect(() => {

  //  const unsub = onSnapshot(doc(db, "admin", "experts"), (doc) => {
  //       setDefaultExperts(doc.data())
  //   });

  //   unsub()
  // }, [])

  // async function addToDB() {
  //   await setDoc(doc(db, "admin", "plans"),

  //   {
  //     Starter: {
  //       duration: 3,
  //       maximum: 19500,
  //       minimum: 11000,
  //       name: "Starter",
  //       roi: 200,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "200%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "19500"
  //       },
  //       ]
  //     },
  //     Lite: {
  //       duration: 3,
  //       maximum: 49500,
  //       minimum: 20000,
  //       name: "Lite",
  //       roi: 300,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "300%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "49500"
  //       },
  //       ]
  //     },
  //     Classic: {
  //       duration: 3,
  //       maximum: 99500,
  //       minimum: 50000,
  //       name: "Classic",
  //       roi: 350,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "350%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "99500"
  //       },
  //       ]
  //     },
  //     Pro: {
  //       duration: 3,
  //       maximum: 1000000,
  //       minimum: 100000,
  //       name: "Pro",
  //       roi: 403,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "403%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "1000000"
  //       },
  //       ]
  //     }
  //   }

  //   ).then(() => {
  //     console.log("done");
  //   });
  // }

  async function fetchDocs() {
    const docRef = doc(db, "admin", "experts");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setDefaultExperts(docSnap.data());
    } else {
      console.log("No such document!");
    }
  }

  useEffect(() => {
    async function fetchDocs() {
      const docRef = doc(db, "admin", "experts");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setDefaultExperts(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    fetchDocs();
  }, []);

  // admin/plans admin/experts

  useEffect(() => {
    if (!searchRef.current?.value) {
      setSearchExperts(false);
    }
  }, [searchRef.current?.value]);

  function handleExperts(e) {
    const value = e.target.value;
    if (!value) {
      setSearchExperts(false);
    }

    let filter = [];

    Object.values(defaultExperts).forEach((expert) => {
      if (
        String(expert.name).toLowerCase().includes(String(value).toLowerCase())
      ) {
        filter.push(expert);
      } else {
        setSearchExperts(false);
      }
    });

    setSearchExperts(filter);
  }

  function handleSubscribe(name, subscriberList) {
    setIsSubscribing(name);
    // setIsSubscribing(true)

    let list = [];

    if (subscriberList) {
      subscriberList.forEach((sub) => {
        list.push(sub);
      });
    }

    list.push(user.uid);

    setSubscribe(name, list);
  }

  async function setSubscribe(name, list) {
    const ref = doc(db, "admin", "experts");
    const index = `${name}.subscriberList`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        toast.success("Expert successfully copied");
        setIsSubscribing(false);
        fetchDocs();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsSubscribing(false);
      });
  }

  function handleUnsubscribe(name, subscriberList) {
    setIsUnSubscribing(name);

    const list = subscriberList.filter((sub) => sub !== user.uid);

    setTimeout(() => {}, 600);

    setUnsubscribe(name, list);
  }

  async function setUnsubscribe(name, list) {
    const ref = doc(db, "admin", "experts");
    const index = `${name}.subscriberList`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        toast.success("Copying canceled successfully");
        setIsUnSubscribing(false);
        fetchDocs();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsUnSubscribing(false);
      });
  }

  async function handleEditExpert(expert) {
    setExpert(expert);

    setEditExpert(true);
  }

  async function handleAddExpert() {
    setAddExpert(true);
  }

  async function addTrades() {
    // addToDBOne();
    // const q = query(
    //   collection(db, "trades"),
    //   where("user", "==", "CvQesoEAjqb9muCs8tMmsJ91fqT2")
    // );
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   const { ref } = doc.data();
    //   deleteStuff(ref);
    // tradesList.push(doc.data());
    // console.log(doc.data())
    // setTrades(tradesList);
    // });
  }

  // async function deleteStuff(ref) {
  //   const profile = doc(db, "trades", ref);
  //   await deleteDoc(profile)
  //     .then(() => {
  //       console.log("deleted");
  //       // deleteUserData();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  async function addToDBOne() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "ETH";
    const amount = 1432;
    const action = "Sell";
    const entry = 1662.22;
    const price = 1563.02;
    const pnl = 0.5467;
    const converted = 0.843;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done one");
      // addToDBTwo();
    });
  }

  async function addToDBTwo() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 2;
    const action = "Sell";
    const entry = 27874.5;
    const price = 27344.9;
    const pnl = 0.01;
    const converted = 54738.6;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBThree();
    });
  }

  async function addToDBThree() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "MATIC";
    const amount = 2;
    const action = "Sell";
    const entry = 0.5884;
    const price = 0.5253;
    const pnl = 0.01;
    const converted = 4.0841;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBFour();
    });
  }

  async function addToDBFour() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "DOT";
    const amount = 60;
    const action = "Sell";
    const entry = 4.25;
    const price = 3.802;
    const pnl = 16.6352;
    const converted = 16.6352;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBFive();
    });
  }

  async function addToDBFive() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 0.3;
    const action = "Sell";
    const entry = 27483.55;
    const price = 27337.9;
    const pnl = 43.69;
    const converted = 43.69;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBSix();
    });
  }

  async function addToDBSix() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "ETH";
    const amount = 75;
    const action = "Sell";
    const entry = 1682.74;
    const price = 1557.81;
    const pnl = 9370.22;
    const converted = 9370.22;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBSeven();
    });
  }

  async function addToDBSeven() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "AVAX";
    const amount = 1432;
    const action = "Sell";
    const entry = 10.3934;
    const price = 9.3851;
    const pnl = 1443.95;
    const converted = 1443.95;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBEight();
    });
  }

  async function addToDBEight() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 1.168;
    const action = "Sell";
    const entry = 27658.23;
    const price = 27337.68;
    const pnl = 374.41;
    const converted = 374.41;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBNine();
    });
  }

  async function addToDBNine() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 0.3;
    const action = "Sell";
    const entry = 28570.0;
    const price = 27342.9;
    const pnl = 122.71;
    const converted = 122.71;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTen();
    });
  }

  async function addToDBTen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 0.948;
    const action = "Sell";
    const entry = 27888.1;
    const price = 27346.9;
    const pnl = 513.05;
    const converted = 513.05;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBEleven();
    });
  }

  async function addToDBEleven() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 0.146;
    const action = "Sell";
    const entry = 27305.0;
    const price = 27352.4;
    const pnl = 6.92;
    const converted = 6.92;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwelve();
    });
  }

  async function addToDBTwelve() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "AVAX";
    const amount = 1011;
    const action = "Sell";
    const entry = 9.6028;
    const price = 9.367;
    const pnl = 238.48;
    const converted = 238.48;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBThirteen();
    });
  }

  async function addToDBThirteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "APE";
    const amount = 4957;
    const action = "Buy";
    const entry = 1.008;
    const price = 1.012;
    const pnl = 19.82;
    const converted = 19.82;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBFourteen();
    });
  }

  async function addToDBFourteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "SOL";
    const amount = 117;
    const action = "Sell";
    const entry = 21.997;
    const price = 21.943;
    const pnl = 6.31;
    const converted = 6.31;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBFifteen();
    });
  }

  async function addToDBFifteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 150;
    const action = "Sell";
    const entry = 28326.4;
    const price = 27385.8;
    const pnl = 0.0181;
    const converted = 0.0181;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBSixteen();
    });
  }

  async function addToDBSixteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "MATIC";
    const amount = 23;
    const action = "Sell";
    const entry = 1.1384;
    const price = 0.5279;
    const pnl = 233.6439;
    const converted = 233.6439;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBSeventeen();
    });
  }

  async function addToDBSeventeen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "ETH";
    const amount = 92;
    const action = "Sell";
    const entry = 1838.54;
    const price = 1565.66;
    const pnl = 148.42;
    const converted = 148.42;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBEighteen();
    });
  }

  async function addToDBEighteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "ETH";
    const amount = 26461;
    const action = "Sell";
    const entry = 1979.58;
    const price = 1565.84;
    const pnl = 35.3199;
    const converted = 35.3199;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBNineteen();
    });
  }

  async function addToDBNineteen() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 32411;
    const action = "Sell";
    const entry = 28753.3;
    const price = 27393.8;
    const pnl = 5.5946;
    const converted = 5.5946;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwenty();
    });
  }

  async function addToDBTwenty() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 75;
    const action = "Sell";
    const entry = 30411.8;
    const price = 27710.6;
    const pnl = 0.24;
    const converted = 0.24;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwentyOne();
    });
  }

  async function addToDBTwentyOne() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "ETH";
    const amount = 80;
    const action = "Sell";
    const entry = 1888.88;
    const price = 1574.3;
    const pnl = 0.846;
    const converted = 0.846;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwentyTwo();
    });
  }

  async function addToDBTwentyTwo() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 5480;
    const action = "Sell";
    const entry = 28098.6;
    const price = 27692.6;
    const pnl = 0.2963;
    const converted = 0.2963;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwentyThree();
    });
  }

  async function addToDBTwentyThree() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "BTC";
    const amount = 3.264;
    const action = "Sell";
    const entry = 27984.11;
    const price = 27366.69;
    const pnl = 2015.28;
    const converted = 2015.28;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwentyFour();
    });
  }

  async function addToDBTwentyFour() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "TRX";
    const amount = 575;
    const action = "Sell";
    const entry = 0.08571;
    const price = 0.08571;
    const pnl = 82.434;
    const converted = 82.434;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done two");
      addToDBTwentyFive();
    });
  }

  async function addToDBTwentyFive() {
    const usID = "CvQesoEAjqb9muCs8tMmsJ91fqT2";

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      usID.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const asset = "DOT";
    const amount = 23;
    const action = "Sell";
    const entry = 4.45;
    const price = 3.804;
    const pnl = 38.16;
    const converted = 38.16;

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type: "Crypto",
      user: usID,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price,
      action,
      pnl,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    }).then(() => {
      console.log("done all");
      return;
    });
  }

  // async function addToDB() {
  //   await setDoc(doc(db, "admin", "addresses"), {
  //     Bitcoin: {
  //       address: "1MPrhyUvqaXH9MFU9h6sAt9zkNQHquJJmr",
  //       name: "Bitcoin",
  //       symbol: "BTC",
  //     },
  //     Ethereum: {
  //       address: "0x14448e41e9429817917A86eFc8d851828533d23a",
  //       name: "Ethereum",
  //       symbol: "ETH",
  //     },
  //     Ripple: {
  //       address: "rEGR1TcyWt8vTDYEdKg4v3sJNT7hp61Wv2",
  //       name: "Ripple",
  //       symbol: "XRP",
  //       tag: "",
  //     },
  //   }).then(() => {
  //     console.log("done");
  //   });
  // }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          {addExpert && <AddExpertModal open={{ addExpert, setAddExpert }} />}

          {editExpert && (
            <EditExpertModal
              open={{ editExpert, setEditExpert }}
              user={userData}
              expert={expert}
            />
          )}
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Copy Experts"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Copy Expert Trades</TitleText>
              <ContentWrapper>
                <div className="flex space-x-4">
                  {userData.admin && (
                    <button
                      className="actionButton"
                      onClick={() => handleAddExpert()}
                      // onClick={() => addToDB()}
                    >
                      <p className="actionText w-[100%]">Add expert</p>
                    </button>
                  )}

                  <Search className="searchContainer">
                    <input
                      type="text"
                      placeholder="Search for an expert"
                      name="search"
                      onChange={handleExperts}
                      ref={searchRef}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </Search>
                </div>

                {/* <BlueButton onClick={() => addToDB()}>ADD DB</BlueButton> */}

                <div
                  className="w-full flex gap-[16px] w-full"
                  style={{
                    flexWrap: "wrap",
                    justifyContent: mobile && "center",
                  }}
                >
                  {searchExperts
                    ? searchExperts.map((expert) => (
                        <div key={expert.name}>
                          <div
                            className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="grid gap-[24px]">
                              <figure>
                                <img
                                  src={expert.picture}
                                  alt="expert"
                                  className="w-[120px] h-[120px] rounded-full"
                                  style={{ objectFit: "cover" }}
                                />
                              </figure>

                              <div className="flex gap-2 items-center">
                                <p className="font-bold text-[28px]">
                                  {expert.name}
                                </p>
                                {expert.subscriberList?.includes(user.uid) && (
                                  <img
                                    src="/extraicons/checkmark.svg"
                                    alt="following"
                                  />
                                )}
                              </div>

                              <div
                                className="flex max-w-[max-content] gap-[8px] mt-[8px]"
                                style={{ flexWrap: "wrap" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    win rate
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.winRate}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    profit share
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.profitShare}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    wins
                                  </p>
                                  <p className="text-[16px]">{expert.wins}</p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    losses
                                  </p>
                                  <p className="text-[16px]">{expert.losses}</p>
                                </div>
                              </div>

                              <div className="mt-[58px]">
                                {expert.subscriberList?.includes(user.uid) ? (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleUnsubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#E64B60",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isUnSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Cancel"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleSubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#1199fa",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Copy"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* {!expert.subscriberList?.includes(user.uid) &&   <div className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]" style={{borderRadius: "10px"}}>
                             <div className="grid gap-[24px]">
                                 <figure>
                                     <img src={expert.picture} alt="expert" className="w-[120px] h-[120px] rounded-full" style={{objectFit: "cover"}}/>
                                 </figure>
         
                                 <div className="flex gap-2 items-center">
                                 <p className="font-bold text-[28px]">{expert.name}</p>
                                 {expert.subscriberList?.includes(user.uid) && <img src="/extraicons/checkmark.svg" alt="following" />}
                                 </div>
         
                                 <div className="flex max-w-[max-content] gap-[8px] mt-[8px]" style={{flexWrap: "wrap"}}>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">win rate</p>
                                         <p className="text-[16px]">{expert.winRate}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">profit share</p>
                                         <p className="text-[16px]">{expert.profitShare}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">wins</p>
                                         <p className="text-[16px]">{expert.wins}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">losses</p>
                                         <p className="text-[16px]">{expert.losses}</p>
                                     </div>
                                 </div>
         
         
                                 <div className="mt-[58px]">
                                     {expert.subscriberList?.includes(user.uid) ? 
                                 
                                      <button onClick={() => handleUnsubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#E64B60", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>{ isUnSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Cancel" }</button> 
                                    
                                      
                                      : <button onClick={() => handleSubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#1199fa", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>
                                     { isSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Copy" }</button>}
                                 </div>
         
         
                             </div>
                         </div>} */}
                        </div>
                      ))
                    : Object.values(defaultExperts).map((expert) => (
                        <div key={expert.name}>
                          <div
                            className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="grid gap-[24px]">
                              <figure>
                                <img
                                  src={expert.picture}
                                  alt="expert"
                                  className="w-[120px] h-[120px] rounded-full"
                                  style={{ objectFit: "cover" }}
                                />
                              </figure>

                              <div className="flex gap-2 items-center">
                                <p className="font-bold text-[28px]">
                                  {expert.name}
                                </p>
                                {expert.subscriberList?.includes(user.uid) && (
                                  <img
                                    src="/extraicons/checkmark.svg"
                                    alt="following"
                                  />
                                )}
                              </div>

                              <div
                                className="flex max-w-[max-content] gap-[8px] mt-[8px]"
                                style={{ flexWrap: "wrap" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    win rate
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.winRate}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    profit share
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.profitShare}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    wins
                                  </p>
                                  <p className="text-[16px]">{expert.wins}</p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    losses
                                  </p>
                                  <p className="text-[16px]">{expert.losses}</p>
                                </div>
                              </div>

                              <div className="mt-[58px]">
                                {expert.subscriberList?.includes(user.uid) ? (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleUnsubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#E64B60",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isUnSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Cancel"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleSubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#1199fa",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Copy"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* {!expert.subscriberList?.includes(user.uid) &&   <div className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]" style={{borderRadius: "10px"}}>
                             <div className="grid gap-[24px]">
                                 <figure>
                                     <img src={expert.picture} alt="expert" className="w-[120px] h-[120px] rounded-full" style={{objectFit: "cover"}}/>
                                 </figure>
         
                                 <div className="flex gap-2 items-center">
                                 <p className="font-bold text-[28px]">{expert.name}</p>
                                 {expert.subscriberList?.includes(user.uid) && <img src="/extraicons/checkmark.svg" alt="following" />}
                                 </div>
         
                                 <div className="flex max-w-[max-content] gap-[8px] mt-[8px]" style={{flexWrap: "wrap"}}>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">win rate</p>
                                         <p className="text-[16px]">{expert.winRate}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">profit share</p>
                                         <p className="text-[16px]">{expert.profitShare}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">wins</p>
                                         <p className="text-[16px]">{expert.wins}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">losses</p>
                                         <p className="text-[16px]">{expert.losses}</p>
                                     </div>
                                 </div>
         
         
                                 <div className="mt-[58px]">
                                     {expert.subscriberList?.includes(user.uid) ? 
                                 
                                      <button onClick={() => handleUnsubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#E64B60", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>{ isUnSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Cancel" }</button> 
                                    
                                      
                                      : <button onClick={() => handleSubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#1199fa", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>
                                     { isSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Copy" }</button>}
                                 </div>
         
         
                             </div>
                         </div>} */}
                        </div>
                      ))}
                </div>
              </ContentWrapper>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;

  .content {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 2.5rem;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 2rem;

  .tables {
    width: 100%;
    overflow-x: auto;
  }

  .switches {
    max-width: 280px;
  }

  .cardContainer {
    max-width: 280px;
  }

  .searchContainer {
    max-width: 280px;
  }

  @media screen and (max-width: 800px) {
    .cardContainer {
      max-width: 100%;
    }

    .searchContainer {
      max-width: 100%;
    }
  }

  .card {
    box-sizing: border-box;
    max-width: 100%;
  }

  .bodyText {
    font-size: 18px;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }
`;

export default CopyExperts;
