import {  useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, increment, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";
import { userContext } from "../context/userContext";

const AddUserProfitsModal = ({ open, user, profits }) => {
  const { addProfits, setAddProfits } = open;
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [account, setAccount] = useState("Starter");
  const [coin, setCoin] = useState('BTC')
  const {currentPrices} = useContext(userContext)

  function handleAccount(e) {
    const value = e.target.value;

    if (value) {
      setAccount(value);
    } else {
      setAccount("");
    }
  }

  function handleUpgrade() {
    setIsUpgrading(true)
    if(coin === "USD"){
      // console.log('usd')
      handleChange(user.id)
    } else {
      // console.log(coin)
      handleAdditionalChange(user.id)
    }
    // setIsUpgrading(true);
    // console.log(coin)
    // handleChange(user.id);
  }

  async function handleAdditionalChange(id) {
    const selected = doc(db, "additionalProfits", id);

    
    await updateDoc(selected, {
      [coin]: increment(Number(account)),
    })
      .then(() => {
    //    handleNotification(id)
    handleAdditionalProfits(id)
      })
      .catch((error) => {
        setIsUpgrading(false);
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
      });
  }


        // Increments the user account balance and calls to send notification
        async function handleAdditionalProfits(user) {
          const q = doc(db, "accounts", user);

          const key = `live.Crypto.${coin}.value`;
  
          try {
            await updateDoc(q, {
              [key]: increment(Number(account)),
            }).then(() => {
              handleAdditionalNotification(user);
            });
          } catch (error) {
              setIsUpgrading(false);
              toast.error(
                "There was a problem with your request.\n \n Please try again later."
              );
          }
        }

  async function handleChange(id) {
    const selected = doc(db, "profits", id);
    await updateDoc(selected, {
      profits: increment(Number(account)),
    })
      .then(() => {
    //    handleNotification(id)
    handleAddProfits(id)
      })
      .catch((error) => {
        setIsUpgrading(false);
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
      });
  }


      // Increments the user account balance and calls to send notification
      async function handleAddProfits(user) {
        const q = doc(db, "accounts", user);

        const key = `live.Fiat.value`;

        try {
          await updateDoc(q, {
            [key]: increment(Number(account)),
          }).then(() => {
            handleNotification(user);
          });
        } catch (error) {
            setIsUpgrading(false);
            toast.error(
              "There was a problem with your request.\n \n Please try again later."
            );
        }
      }

        //send approve notification
        async function handleNotification(user) {
            const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
            const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
        
            const str =
              user.substring(0, 4) + randomOne.toString() + randomTwo.toString();
        
            await setDoc(doc(db, "notifications", str), {
              date: serverTimestamp(),
              ref: str,
              user,
              title: "Profits",
              text: account > 0 && "You earned a profit of " + "$" + account ,
            })
              .then(() => {
                toast.success("Added Successfully");
                setIsUpgrading(false);
                setAddProfits(false);
              })
              .catch((error) => {
                setIsUpgrading(false);
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
              });
          }

              //send approve notification
        async function handleAdditionalNotification(user) {
          const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
          const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
      
          const str =
            user.substring(0, 4) + randomOne.toString() + randomTwo.toString();
      
          await setDoc(doc(db, "notifications", str), {
            date: serverTimestamp(),
            ref: str,
            user,
            title: "Profits",
            text: account > 0 && "You earned a profit of " + "$" + Number(currentPrices[coin] * account).toFixed(2) ,
          })
            .then(() => {
              toast.success("Added Successfully");
              setIsUpgrading(false);
              setAddProfits(false);
            })
            .catch((error) => {
              setIsUpgrading(false);
      toast.error(
        "There was a problem with your request.\n \n Please try again later."
      );
            });
        }

  return (
    <Modal
      open={addProfits}
      onClose={() => setAddProfits(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Add profits</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddProfits(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div className="addcon">
            <label htmlFor="new">Client Name</label>
            <div className="add">
              <input
                //   ref={cryptoAddressRef}
                disabled
                placeholder={user?.firstname}
                defaultValue={user?.firstname}
                //   onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          <div
                          className="selectWrap"
                          style={{
                            boxSizing: "border-box",
                          }}
                        >
                          <label htmlFor="asset">Asset</label>
                          <div
                            className="selectBox"
                            style={{
                              boxSizing: "border-box",
                              width: "100%",
                            }}
                          >
                            <div className="selectTop">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                  width: "100%",
                                  gap: "0.5rem",
                                  boxSizing: "border-box",
                                  padding: "1rem",
                                }}
                              >
                                <img
                                  src={`/asseticons/${coin}.svg`}
                                  alt="icon"
                                  width={24}
                                  height={24}
                                />
                                <select
                                  name="asset"
                                  style={{
                                    padding: "0",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    display: "flex",
                                  }}
                                  onChange={(e) => setCoin(e.target.value)}
                                >
                                  <option>BTC</option>
                                  <option>ETH</option>
                                  {/* <option>USDT</option> */}
                                  <option>USD</option>
                                </select>
                              </div>
                              <img
                                src="/extraicons/arrowdown.svg"
                                alt="select"
                                className="dropDownIcon"
                              />
                              {/* </span> */}
                            </div>
                          </div>
                        </div>

          {/* {user.tradingProgress && (
            <div className="addcon">
              <label htmlFor="new">Trading Progress</label>
              <div className="add">
                <input
                 onChange={handleAccount}
                //   disabled
                  placeholder={user.tradingProgress}
                //   defaultValue={user.tradingProgress}
                />
              </div>
            </div>
          )} */}

        <div className="addcon">
            <label htmlFor="new">Amount</label>
            <div className="add">
              <input
                //   ref={cryptoAddressRef}
                // disabled
                type='number'
                onChange={handleAccount}
                placeholder='0'
                // defaultValue='1000'
                //   onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="selectWrap">
            <label htmlFor="type">New Trading Progress</label>
            <div className="selectBox">
              <div className="selectTop">
                <input
                  onChange={handleAccount}
                  placeholder={user.tradingProgress}
                  defaultValue={user.tradingProgress}
                />
                <img src="/extraicons/arrowdown.svg" alt="select" />
              </div>
            </div>
          </div> */}
          <button
            className={!account ? "button disabled" : "button submit"}
            disabled={isUpgrading || !account}
            onClick={handleUpgrade}
          >
            {isUpgrading ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                style={{
                  padding: "10px",
                  width: "20px",
                  height: "auto",
                }}
              />
            ) : (
              <p style={{ textDecoration: "none", color: "white",maxWidth: "max-content", placeSelf: "center" }}>Add</p>
            )}
          </button>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default AddUserProfitsModal;
