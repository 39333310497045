import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

const Crypto = ({ action, assets, traded }) => {
  const { hasTraded, setHasTraded } = traded;

  // const {active, setActive} = type;

  const { accounts, currentPrices, currentAccount } = useContext(userContext);
  const [asset, setAsset] = useState("AAVEUSD");
  const [assetPrice, setAssetPrice] = useState(
    currentPrices[asset.slice(0, -3)]
  );
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user] = useAuthState(auth);
  const [id, setID] = useState("");
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  // const [accounts, setAccounts] = useState([]);
  const [time, setTime] = useState(5);
  const [tp, setTP] = useState("");
  const [sl, setSL] = useState("");
  const [tpBuyError, setTPBuyError] = useState(false);
  const [tpSellError, setTPSellError] = useState(false);
  const [slBuyError, setSLBuyError] = useState(false);
  const [slSellError, setSLSellError] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const tpRef = useRef();
  const slRef = useRef();
  const entryRef = useRef();

  const [entry, setEntry] = useState(assetPrice);

  const cryptoassets = [
    // { name: "1nch Protocol", symbol: "1INCHUSD" },

    { name: "Aave", symbol: "AAVEUSD", alt: "AAVE" },
    { name: "Cardano", symbol: "ADAUSD", alt: "ADA" },
    { name: "Algorand", symbol: "ALGOUSD", alt: "ALGO" },
    { name: "Anchor Protocol", symbol: "ANCUSD", alt: "ANCUSD" },
    { name: "Apecoin", symbol: "APEUSD", alt: "APE" },
    { name: "Aurora", symbol: "AURORAUSD", alt: "AURORAUSD" },
    { name: "Avalanche", symbol: "AVAXUSD", alt: "AVAX" },
    { name: "Axie Infinity", symbol: "AXSUSD", alt: "AXS" },

    { name: "Bitcoin Cash", symbol: "BCHUSD", alt: "BCH" },
    { name: "Boring DAO", symbol: "BORINGUSD", alt: "BORINGUSDT" },
    { name: "Bitcoin", symbol: "BTCUSD", alt: "BTC" },
    { name: "Bitcoin Gold", symbol: "BTGUSD", alt: "BTGUSD" },

    { name: "Comdex", symbol: "CMDXUSD", alt: "CMDX" },
    { name: "Cronos", symbol: "CROUSD", alt: "CROUSD" },

    { name: "Dai", symbol: "DAIUSD", alt: "DAI" },
    // { name: "Dao Maker", symbol: "DAOUSD" },
    { name: "Dash", symbol: "DASHUSD", alt: "DASHUSD" },
    { name: "Defi Land", symbol: "DFLUSD", alt: "DFLUSD" },
    { name: "Polkadot", symbol: "DOTUSD", alt: "DOT" },
    { name: "Dogecoin", symbol: "DOGEUSD", alt: "DOGE" },

    { name: "Evmos", symbol: "EVMOSUSD", alt: "EVMOSUSDT" },
    { name: "Ethereum Classic", symbol: "ETCUSD", alt: "ETC" },
    { name: "Ethereum", symbol: "ETHUSD", alt: "ETH" },

    { name: "Stepn", symbol: "GMTUSD", alt: "STEPUSD" },
    { name: "Gochain", symbol: "GOUSD", alt: "GOUSD" },
    { name: "The Graph", symbol: "GRTUSD", alt: "GRTUSD" },
    { name: "Gate Token", symbol: "GTUSD", alt: "GTUSD" },

    { name: "ChainLink", symbol: "LINKUSD", alt: "LINK" },
    { name: "Link", symbol: "LNUSD", alt: "LNUSDT" },
    { name: "Litecoin", symbol: "LTCUSD", alt: "LTC" },

    { name: "Decentraland", symbol: "MANAUSD", alt: "MANA" },
    { name: "Matic", symbol: "MATICUSD", alt: "MATIC" },

    { name: "Nexo", symbol: "NEXOUSD", alt: "NEXPUSD" },
    { name: "Energi", symbol: "NRGUSD", alt: "NRGUSDT" },

    { name: "Origin Protocol", symbol: "OGNUSD", alt: "OGNUSDT" },
    { name: "Okb", symbol: "OKBUSD", alt: "OKBUSDT" },
    { name: "Optimism", symbol: "OPUSD", alt: "OPUSDT" },
    { name: "Orion Protocol", symbol: "ORNUSD", alt: "ORNUSDT" },

    { name: "Raini", symbol: "RAINIUSD", alt: "RAINIUSD_165C19" },
    { name: "Rarible", symbol: "RARIUSD", alt: "RARIUSD" },
    { name: "Ripple", symbol: "XRPUSD", alt: "XRPUSD" },

    { name: "Safepal", symbol: "SFPUSD", alt: "SFPUSDT" },
    { name: "Shiba Inu", symbol: "SHIBUSD", alt: "SHIB" },
    { name: "Step Finance", symbol: "STEPUSD", alt: "STEPUSD" },
    { name: "Sushi", symbol: "SUSHIUSD", alt: "SUSHI" },

    // { name: "Wonderland", symbol: "TIMEUSD" },
    { name: "Telos", symbol: "TLOSUSD", alt: "TLOSUSD" },
    { name: "Tron", symbol: "TRXUSD", alt: "TRX" },

    { name: "Solana", symbol: "SOLUSD", alt: "SOL" },

    { name: "Uniswap", symbol: "UNIUSD", alt: "UNIUSD" },
    { name: "USD Coin", symbol: "USDCUSD", alt: "USDC" },
    { name: "Tether", symbol: "USDTUSD", alt: "USDT" },

    { name: "VeChain", symbol: "VETUSD", alt: "VETUSD" },

    { name: "Wing Finance", symbol: "WINGUSD", alt: "WINGUSD" },

    { name: "Chain", symbol: "XCNUSD", alt: "XCNUSD" },
    { name: "Stellar", symbol: "XLMUSD", alt: "XLM" },
    { name: "Monero", symbol: "XMRUSD", alt: "XMR" },
    { name: "Proton", symbol: "XPRUSD", alt: "XPRUSDT" },
    { name: "Tezos", symbol: "XTZUSD", alt: "XTZ" },

    { name: "Zcash", symbol: "ZECUSD", alt: "ZEXUSDT" },
    { name: "Zignaly", symbol: "ZIGUSD", alt: "ZIGUSDT" },
  ];

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setCryptoAccounts(liveAccount.Crypto);
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setCryptoAccounts(practiceAccount.Crypto);
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (fiatAccount && cryptoAccounts) {
      if (action === "buy") {
        setBalance(fiatAccount?.value);
      }

      if (action === "sell") {
        setBalance(cryptoAccounts[asset?.slice(0, -3)]?.value);
      }
    }
  }, [fiatAccount, action, asset, balance, amount, cryptoAccounts]);

  function handleAssetChange(e) {
    const value = e.target.value;

    assets.forEach((acc) => {
      if (acc.name === value) {
        setAsset(acc.symbol);
      }
    });
  }

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (asset) {
      setAssetPrice(currentPrices[asset.slice(0, -3)]);
      setEntry(currentPrices[asset.slice(0, -3)]);
    }
  }, [asset, currentPrices]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
    // if (value > balance) {
    //   setAmountError(true);
    // } else {
    //   setAmountError(false);
    // }
  }

  function handleEntry(e) {
    const value = e.target.value;
    if (value > 0) {
      setEntry(value);
    } else {
      setEntry(assetPrice);
    }
  }

  function handleTP(e) {
    const value = e.target.value;
    if (value > 0) {
      setTP(value);
    } else {
      setTP("");
    }

    // if (tp && action === "buy" && value <= assetPrice) {
    //   setTPBuyError(true);
    // } else {
    //   setTPBuyError(false);
    // }

    // if (tp && action === "sell" && value >= assetPrice) {
    //   setTPSellError(true);
    // } else {
    //   setTPSellError(false);
    // }
  }

  function handleSL(e) {
    const value = e.target.value;
    if (value > 0) {
      setSL(value);
    } else {
      setSL("");
    }

    // if (sl && action === "buy" && value >= assetPrice) {
    //   setSLBuyError(true);
    // } else {
    //   setSLBuyError(false);
    // }

    // if (sl && action === "sell" && value <= assetPrice) {
    //   setSLSellError(true);
    // } else {
    //   setSLSellError(false);
    // }
  }

  function handleTimeInForce(e) {
    const value = e.target.value;
    const timeSlice = value.slice(0, value.indexOf(" "));

    if (value.includes("minute")) {
      setTime(Number(timeSlice) * 10);
    }
    if (value.includes("hour")) {
      setTime(Number(timeSlice) * 10);
    }
    if (value.includes("day")) {
      setTime(Number(timeSlice) * 10);
    }
    if (value.includes("week")) {
      setTime(Number(timeSlice) * 10);
    }
    // if(value.includes('month')) {}
    // if(value.includes('year')) {}

    // setTime(Number(value));
  }

  function reset() {
    setAmount("");
    setAmountError(false);
    setTPBuyError(false);
    setTPSellError(false);
    setSLBuyError(false);
    setSLSellError(false);

    if (amountRef) {
      amountRef.current.value = "";
    }

    // if(entryRef){
    //   entryRef.current.value = "";
    // }

    if (isAdvanced && slRef) {
      slRef.current.value = "";
    }

    if (isAdvanced && tpRef) {
      tpRef.current.value = "";
    }
  }

  const handleTrade = () => {
    setIsSubmitting(true);

    if (!isAdvanced) {
      if (action === "buy") {
        handleCryptoBuy();
      }

      if (action === "sell") {
        handleCryptoSell();
      }
    } else {
      if (action === "buy") {
        handleAdvancedBuy();
      }

      if (action === "sell") {
        handleAdvancedSell();
      }
    }
  };

  function handleCryptoBuy() {
    const type = "Crypto";
    const action = "Buy";
    const converted = Number(
      amount / currentPrices[asset.slice(0, -3)]
    ).toFixed(2);

    submitCryptoBuyOrder(
      asset.slice(0, -3),
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement fiat
  const decrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementCrypto(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment crypto
  const incrementCrypto = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitCryptoBuyOrder(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitCryptoBuyOrder(
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type,
      user: id,
      amount,
      ass: asset + "USD",
      asset,
      info: { entry },
      price: assetPrice,
      action,
      pnl: amount,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Buy Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Order Could Not Complete.");
      });
  }

  function handleCryptoSell() {
    // decrement crypto
    // increment fiat
    // set trade doc
    // send email
    const type = "Crypto";
    const action = "Sell";
    const converted = Number(
      currentPrices[asset?.slice(0, -3)] * Number(amount).toFixed(2)
    );
    submitCryptoSellOrder(
      asset?.slice(0, -3),
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement crypto
  const decrementCrypto = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementAmount(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment fiat
  const incrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitCryptoSellOrder(
          asset,
          amount,
          id,
          price,
          type,
          action,
          converted
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitCryptoSellOrder(
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type,
      user: id,
      amount,
      asset,
      price: assetPrice,
      action,
      info: { entry },
      ass: asset + "USD",
      pnl: converted,
      converted,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Sell Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Order Could Not Complete.");
      });
  }

  async function handleAdvancedBuy() {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        submitTrade();
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAdvancedSell() {
    const symbol = asset.slice(0, -3);

    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Crypto.${symbol}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        submitTrade();
      });
    } catch (error) {
      console.log(error);
    }
  }

  //submit Trade Advanced
  async function submitTrade() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const details = {
      advanced: true,
      action,
      account: currentAccount,
      tp,
      sl,
      entry,
      asset,
      time,
      amount,
      symbol: asset,
    };

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      type: "Crypto",
      user: id,
      amount,
      asset,
      price: assetPrice,
      action,
      info: details,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: assetPrice,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        postTrade(id, str, details);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Order Could Not Complete.");
      });
  }

  async function postTrade(user, str, details) {
    const url =
      "https://fin-http-production.up.railway.app/liveaccesstrade/crypto";

    const base = {
      user,
      ref: str,
      details,
    };

    const config = {
      method: "POST",
      // mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          toast.success("Trade sucessfully placed.");
          setIsSubmitting(false);
          setHasTraded(hasTraded === 456 ? 234 : 456);
          reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const amountRef = useRef();

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handleAssetChange}>
              {assets.map((ass) => (
                <option key={ass.symbol}>{ass.name}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
            {/* </span> */}
          </div>

          <div className="captions">
            <span></span>

            {assetPrice && (
              <p className="balance">
                Current Price{" "}
                <span className="green">~${Number(assetPrice).toFixed(2)}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Lot size</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleAmount}
              ref={amountRef}
            />
          </div>

          {/* <div className="captions">
            <p className="balance">
              {amount && (
                <span>
                  Current Balance {""}
                  <span className={balance > 0 ? "green" : "red"}>
                    ~
                    {balance > 0.01
                      ? Number(balance).toFixed(3)
                      : Number(balance)}{" "}
                    {action === "buy" ? "USD" : asset.slice(0, -3)}{" "}
                    {balance < 0.01 && (
                      <Link to="/deposit" className="red">
                        Deposit now
                      </Link>
                    )}
                  </span>
                </span>
              )}
            </p>

            {amount && (
              <>
                {action === "buy" && (
                  <p className="extra">
                    {asset.slice(0, -3)} ~
                    <span className="green">
                      {Number(
                        amount / currentPrices[asset.slice(0, -3)]
                      ).toFixed(2)}
                    </span>
                  </p>
                )}

                {action === "sell" && (
                  <p className="extra">
                    USD ~
                    <span className="green">
                      $
                      {(
                        currentPrices[asset.slice(0, -3)] * Number(amount)
                      ).toFixed(2)}
                    </span>
                  </p>
                )}
              </>
            )}
          </div> */}
          {amountError && (
            <p className="red warning textMedium" style={{ marginTop: "1rem" }}>
              Trade amount cannot exceed current balance.
            </p>
          )}
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Entry Price</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder={Number(assetPrice).toFixed(2)}
              defaultValue={Number(assetPrice).toFixed(2)}
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleEntry}
              ref={entryRef}
            />
          </div>
        </div>
      </div>

      <div
        className="clickable"
        style={{
          display: "flex",
          placeSelf: "end",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "#1199fa" }}
          onChange={() => setIsAdvanced(!isAdvanced)}
        />
        Advanced
      </div>

      {isAdvanced && (
        <div className="amWrap" style={{ transition: "all 300ms ease-in" }}>
          <label htmlFor="takeProfit">Take Profit</label>
          <div className="amBx">
            <div className="amTop">
              <input
                placeholder={assetPrice + 2}
                name="takeProfit"
                type="number"
                ref={tpRef}
                style={{ padding: "1rem" }}
                onChange={handleTP}
              />
            </div>
            {tpBuyError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your TP above the current price.
              </p>
            )}

            {tpSellError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your TP below the current price.
              </p>
            )}
          </div>
        </div>
      )}

      {isAdvanced && (
        <div className="amWrap" style={{ transition: "all 300ms ease-in" }}>
          <label htmlFor="stopLoss">Stop Loss</label>
          <div className="amBx">
            <div className="amTop">
              <input
                ref={slRef}
                placeholder={assetPrice - 2}
                name="stopLoss"
                type="number"
                style={{ padding: "1rem" }}
                onChange={handleSL}
              />
            </div>

            {slBuyError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your SL below the current price.
              </p>
            )}

            {slSellError && (
              <p
                className="red warning textMedium"
                style={{ marginTop: "1rem" }}
              >
                Please place your SL above the current price.
              </p>
            )}
          </div>
        </div>
      )}

      <div className="selectWrap">
        <label htmlFor="timeinforce">Duration</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="timeinforce" onChange={handleTimeInForce}>
              <option>5 minutes</option>
              <option>10 minutes</option>
              <option>15 minutes</option>
              <option>30 minutes</option>
              <option>1 hour</option>
              <option>2 hours</option>
              <option>4 hours</option>
              <option>6 hours</option>
              <option>10 hours</option>
              <option>20 hours</option>
              <option>1 day</option>
              <option>2 days</option>
              <option>3 days</option>
              <option>4 days</option>
              <option>5 days</option>
              <option>6 days</option>
              <option>7 days</option>
              <option>1 week</option>
              <option>2 weeks</option>
              <option>3 weeks</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      {/* <OutlinedBox style={{ padding: "0.5rem", margin: "0", width: "100%" }}>
        <span
          className="infot"
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "360px",
          }}
        >
          {isAdvanced
            ? " Your trade will auto close if SL or TP does not hit in " +
              time +
              " minutes."
            : "Your trade will auto close in " + time + " minutes."}
        </span>
      </OutlinedBox> */}

      <button
        className={
          !amount || !asset || !assetPrice ? "button disabled" : "button submit"
        }
        disabled={!amount || !asset || !assetPrice}
        onClick={handleTrade}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <span className="capitalize" style={{ alignSelf: "center" }}>
            Execute Trade
          </span>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Crypto;
