import assert from "assert";
import {
  doc,
  getDoc,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { BlueButton } from "../../styles";

const Crypto = ({ action, assets, user }) => {
  const { currentPrices } = useContext(userContext);
  const currentAccount = "live";
  const [asset, setAsset] = useState(assets[0].symbol);
  const [assetPrice, setAssetPrice] = useState(
    currentPrices[asset.slice(0, -3)]
  );
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setID] = useState("");
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    async function getData() {
      const docRef = doc(db, "accounts", user);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccounts(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    getData();
  }, []);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setCryptoAccounts(liveAccount?.Crypto);
          setFiatAccount(liveAccount?.Fiat);
          break;
        case "practice":
          setCryptoAccounts(practiceAccount?.Crypto);
          setFiatAccount(practiceAccount?.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (fiatAccount && cryptoAccounts) {
      if (action === "buy") {
        setBalance(fiatAccount?.value);
      }

      if (action === "sell") {
        setBalance(cryptoAccounts[asset.slice(0, -3)]?.value);
      }
    }
  }, [fiatAccount, action, asset, balance, amount, cryptoAccounts]);

  function handleAssetChange(e) {
    const value = e.target.value;
    setAsset(value);
  }

  useEffect(() => {
    if (asset) {
      setAssetPrice(currentPrices[asset.slice(0, -3)]);
    }
  }, [asset]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }

    if (value > balance) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }

  useEffect(() => {
    if (user) {
      setID(user);
    }
  }, [user]);

  const handleTrade = () => {
    setIsSubmitting(true);

    if (action === "sell") {
      handleCryptoSell();
    }

    if (action === "buy") {
      handleCryptoBuy();
    }
  };

  function handleCryptoBuy() {
    const type = "Crypto";
    const action = "Buy";
    const converted = Number(
      amount / currentPrices[asset.slice(0, -3)]
    ).toFixed(2);

    decrementAmount(
      asset.slice(0, -3),
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement fiat
  const decrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementCrypto(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment crypto
  const incrementCrypto = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitCryptoBuyOrder(asset, amount, id, price, type, action);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitCryptoBuyOrder(asset, amount, id, price, type, action) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type,
      user: id,
      amount,
      asset,
      price,
      action,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: price,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Buy Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  function handleCryptoSell() {
    // decrement crypto
    // increment fiat
    // set trade doc
    // send email
    const type = "Crypto";
    const action = "Sell";
    const converted = Number(
      currentPrices[asset.slice(0, -3)] * Number(amount).toFixed(2)
    );
    decrementCrypto(
      asset.slice(0, -3),
      Number(amount),
      id,
      assetPrice,
      type,
      action,
      converted
    );
  }

  // Decrement crypto
  const decrementCrypto = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        incrementAmount(asset, amount, id, price, type, action, converted);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment fiat
  const incrementAmount = async (
    asset,
    amount,
    id,
    price,
    type,
    action,
    converted
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(converted)),
      }).then(() => {
        submitCryptoSellOrder(asset, amount, id, price, type, action);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitCryptoSellOrder(asset, amount, id, price, type, action) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "closed",
      type,
      user: id,
      amount,
      asset,
      price,
      action,
      details: [
        {
          name: "Action",
          value: action,
        },
        {
          name: "Entry Price",
          value: price,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        toast.success("Sell Order Placed");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  const amountRef = useRef();

  function reset() {
    setAmount("");
    setAmountError(false);
    if (amountRef.current) {
      amountRef.current.value = "";
    }
  }

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handleAssetChange}>
              {assets.map((ass) => (
                <option key={ass.symbol}>{ass.symbol}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
            {/* </span> */}
          </div>

          <div className="captions">
            <span></span>

            {assetPrice && (
              <p className="balance">
                Current Price{" "}
                <span className="green">~${Number(assetPrice).toFixed(2)}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Amount</label>
        <div className="amBx">
          <div className="amTop p-2 pl-0">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleAmount}
              ref={amountRef}
            />
          </div>

          <div className="captions">
            <p className="balance">
              {amount && (
                <span>
                  Balance {""}
                  <span className={balance > 0 ? "green" : "red"}>
                    ~
                    {balance > 0.01
                      ? Number(balance).toFixed(3)
                      : Number(balance)}{" "}
                    {action === "buy" ? "USD" : asset.slice(0, -3)}{" "}
                    {balance < 0.01 && (
                      <p className="red">
                        Add to this user's {asset.slice(0, -3)} balance to
                        continue.
                      </p>
                    )}
                  </span>
                </span>
              )}
            </p>

            {amount && (
              <>
                {action === "buy" && (
                  <p className="extra">
                    {asset.slice(0, -3)} ~
                    <span className="green">
                      {Number(
                        amount / currentPrices[asset.slice(0, -3)]
                      ).toFixed(2)}
                    </span>
                  </p>
                )}

                {action === "sell" && (
                  <p className="extra">
                    USD ~
                    <span className="green">
                      $
                      {(
                        currentPrices[asset.slice(0, -3)] * Number(amount)
                      ).toFixed(2)}
                    </span>
                  </p>
                )}
              </>
            )}
          </div>
          {amountError && (
            <p className="red warning textMedium" style={{ marginTop: "1rem" }}>
              Trade amount cannot exceed current balance. Add to this user's{" "}
              {asset.slice(0, -3)} balance to continue.
            </p>
          )}
        </div>
      </div>

      <button
        className={
          !amount || !asset || !assetPrice || !balance || amountError
            ? "button disabled"
            : "button submit"
        }
        disabled={!amount || !asset || !assetPrice || !balance || amountError}
        onClick={handleTrade}
        style={{
          placeContent: "center",
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <>
            Execute Trade
          </>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Crypto;
